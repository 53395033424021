<template lang="pug">
  v-container#advanceModalView(fluid='' tag='section')
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-card
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title Registro de Anticipos y valores por Proveedor
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="closeModal")
            v-icon.primary.rounded mdi-close
      v-card-text
        v-form(ref='formAdvance' v-model='validAdvance' lazy-validation='' autocomplete="random-string")
          v-row
            v-col(cols="12" md="3")
              v-menu(ref='menuFromAdvace' v-model='menuFromAdvace' :close-on-content-click='false' :return-value.sync='dateFromAdvance' transition='scale-transition' offset-y='' min-width='290px')
                template(v-slot:activator='{ on }')
                  v-text-field(readonly v-model='dateFromAdvance' label='Buscar Desde' prepend-icon='mdi-calendar' v-on='on')
                v-date-picker(@change='$refs.menuFromAdvace.save(dateFromAdvance)' v-model='dateFromAdvance' no-title='' scrollable='' :max="dateUntilAdvance" style='margin:0px;' color="green lighten-1" :min="minDateForConsults")
            v-col(cols="10" md="3")
              v-menu(ref='menuUntilAdvance' v-model='menuUntilAdvance' :close-on-content-click='false' :return-value.sync='dateUntilAdvance' transition='scale-transition' offset-y='' min-width='290px')
                template(v-slot:activator='{ on }')
                  v-text-field(readonly v-model='dateUntilAdvance' label='Buscar Hasta' prepend-icon='mdi-calendar' v-on='on')
                v-date-picker(@change='$refs.menuUntilAdvance.save(dateUntilAdvance)' v-model='dateUntilAdvance' no-title='' scrollable='' :min="dateFromAdvance" style='margin:0px;' color="green lighten-1")
            v-col(cols="2" md="1" style="margin-top: 15px;")
              v-tooltip(bottom='')
                template(v-slot:activator='{ attrs, on }')
                  v-btn(v-on="on" icon='' color='gray' style="background-color: RGB(1, 125, 71);" @click="filterByDate" :loading="loadingDataFilterDate")
                    v-icon(style="font-size: 30px; color: white;") mdi-magnify
                span.tooltips FILTRAR POR FECHA
            v-col(cols="12" md="2" style="margin-top: 15px;")
              v-btn.mr-0(color='secondary' @click='downloadData' style="width: 100%;" :loading="loadingDownloadData")
                | DESCARGAR DATOS
            v-col(cols="12" md="3" style="margin-top: 15px;")
              v-btn.mr-0(color='primary' @click='calculateVals' style="width: 100%;" :loading="loadingCalculateVals")
                | CALCULAR DATOS
            v-col(cols="12")
              v-alert(v-model="alertDataSend" dismissible dense='' border='left' type='error')
                | {{msgAlertValsData}}
            v-col(cols='12' md="3")
              v-row
                v-col(cols='12' v-if="!providerData.external" style="padding: 13px 0px 0px 0px;")
                  v-card
                    v-card-text
                      h3(style="text-align: center;") LISTA DE RECIBOS
                      v-row
                        v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
                        v-data-table.mx-2(style="width: 100%;" :headers="headersReceiptView" v-show="!firstLoad" :items="entryReceiptProviderLst ? entryReceiptProviderLst.receipts : []" item-key="uid" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Anticipos por página'\}")
                          v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                            | Sin resultados
                          v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                            | Sin datos
                          v-flex(xs12='', sm10='', offset-sm1='')
                        h3(style="text-align: center; width: 100%;") TOTAL: ${{valReceiptTotal}}
                v-col(cols='12' style="padding: 13px 0px 0px 0px;")
                  v-card
                    v-card-text
                      h3(style="text-align: center;") ANTICIPOS
                      v-row
                        v-col.py-0(cols='12' md='12')
                          v-text-field(@keypress="isNumber($event)" @keyup="calcAdvanceVal()" v-model='arrastra' label='Arrastra' type='text' maxlength="150")
                        v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
                        v-data-table.mx-2(style="width: 100%;" :headers="headersAdvanceView" v-show="!firstLoad" :items="advanceLoadProviderLst" item-key="uid" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Anticipos por página'\}")
                          v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                            | Sin resultados
                          v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                            | Sin datos
                          v-flex(xs12='', sm10='', offset-sm1='')
                        h3(style="text-align: center; width: 100%;") TOTAL: ${{valAdvanceTotalDrags}}
            v-col(v-if="providerData.external" cols='12' md="9")
              v-card
                v-card-text
                  h3(style="text-align: center;") Lista de Guías
                  v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
                  v-row(style="margin-top: 11px;")
                    v-data-table.mx-2(style="width: 100%;" :headers="headersAdvanceViewGuide" v-show="!firstLoad" :items="entryGuidesProviderLst" item-key="uid" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Guías por página'\}")
                      v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                        | Sin resultados
                      v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                        | Sin datos
                      v-flex(xs12='', sm10='', offset-sm1='')
                    h3(style="text-align: right; width: 100%;") TOTAL: ${{valGuideTotal}}
                    br
                    h3(style="text-align: center; width: 100%;") Tabla de Resultados
                    v-data-table.mx-2(style="width: 100%;" :headers="headersAdvanceViewGuideRetencion" v-show="!firstLoad" :items="arrGideEndData" item-key="uid" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Registros por página'\}")
                      v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                        | Sin resultados
                      v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                        | Sin datos
                      v-flex(xs12='', sm10='', offset-sm1='')
                    h3(style="text-align: right; width: 100%;") TOTAL FINAL: ${{endTotalDataVal}}
                    h3(style="text-align: right; width: 100%; color: red;") DIFERENCIA: ${{dieferenceTotalVal}}
            v-col(v-if="!providerData.external" cols='12' md="9")
              v-card
                v-card-text
                  h3(style="text-align: center;") Lista de Recibos por Producto
                  v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
                  v-row(style="margin-top: 11px;")
                    v-data-table.mx-2(style="width: 100%;" :headers="headersReceiptViewGuide" v-show="!firstLoad" :items="entryReceiptProviderLst ? entryReceiptProviderLst.lstReceiptsProductos : []" item-key="uid" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Recibos por página'\}")
                      v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                        | Sin resultados
                      v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                        | Sin datos
                      v-flex(xs12='', sm10='', offset-sm1='')
                    h3(style="text-align: right; width: 100%;") TOTAL: ${{valReceiptProductsTotal}}
                    br
                    h3(style="text-align: center; width: 100%;") Tabla de Resultados
                    v-data-table.mx-2(style="width: 100%;" :headers="headersAdvanceViewGuideRetencion" v-show="!firstLoad" :items="calculateValsData" item-key="uid" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Registros por página'\}")
                      v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                        | Sin resultados
                      v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                        | Sin datos
                      v-flex(xs12='', sm10='', offset-sm1='')
                    h3(style="text-align: right; width: 100%;") TOTAL: ${{endTotalDataValReceipt}}
                    h3(style="text-align: right; width: 100%; color: red;") DIFERENCIA: ${{dieferenceTotalValReceipt}}
</template>
<script>
  // import moment from 'moment'
  import * as functionsVal from '../../../../functions'
  import moment from 'moment/moment'
  var accounting = require('accounting')
  var functions = require('../../../../functions/functions')
  export default {
    props: {
      providerData: {
        type: Object,
        default: function () {
          return {
            providerKey: null,
            external: false,
          }
        },
      },
    },
    data: () => ({
      minDateForConsults: moment().subtract(15, 'days').format('YYYY-MM-DD'),
      loadingDownloadData: false,
      arrastra: '',
      alertDataSend: false,
      msgAlertValsData: '',
      loadingCalculateVals: false,
      loadingDataFilterDate: false,
      menuFromAdvace: false,
      menuUntilAdvance: false,
      dateUntilAdvance: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10),
      dateFromAdvance: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().substr(0, 10),
      headersAdvanceView: [
        { text: 'Fecha', value: 'createDate', align: 'left', filterable: false },
        { text: 'Valor', value: 'total', align: 'left', filterable: true },
        { text: 'Saldo', value: 'balance', align: 'left', filterable: true },
      ],
      headersReceiptView: [
        { text: 'Fecha Emisión', value: 'fechaEmision', align: 'left', filterable: false },
        { text: 'N° Recibo', value: 'secuencial', align: 'left', filterable: true },
        { text: 'Transporte ', value: 'transporte', align: 'left', filterable: true },
        { text: 'Total', value: 'total', align: 'left', filterable: true },
      ],
      headersAdvanceViewGuide: [
        { text: 'Fecha', value: 'dateEntry', align: 'left', filterable: false },
        { text: 'N° Guía', value: 'referralGuide', align: 'left', filterable: true },
        { text: 'N° Placa', value: 'plate', align: 'left', filterable: false },
        { text: 'Peso Entrada', value: 'inputweight', align: 'left', filterable: true },
        { text: 'Descuentos', value: 'discounts', align: 'left', filterable: true },
        { text: 'Material', value: 'item', align: 'left', filterable: true },
        { text: 'Peso Verificado', value: 'weight', align: 'left', filterable: true },
        { text: 'Precio Kilo', value: 'kiloprice', align: 'left', filterable: true },
        { text: 'Total', value: 'total', align: 'left', filterable: true },
      ],
      headersReceiptViewGuide: [
        { text: 'Fecha', value: 'fechaEmision', align: 'left', filterable: false },
        { text: 'N° Recibo', value: 'secuencial', align: 'left', filterable: true },
        { text: 'Proveedor', value: 'proveedor.completeData', align: 'left', filterable: false },
        { text: 'Peso', value: 'cantidad', align: 'left', filterable: true },
        { text: 'Material', value: 'name', align: 'left', filterable: true },
        { text: 'Precio', value: 'valorUnitario', align: 'left', filterable: true },
        { text: 'Total', value: 'total', align: 'left', filterable: true },
      ],
      headersAdvanceViewGuideRetencion: [
        { text: 'PESO VERIFICADO', value: 'pesoVerificado', align: 'left', filterable: true },
        { text: 'PRECIO KILO', value: 'precioKilo', align: 'left', filterable: true },
        { text: 'SUB TOTAL', value: 'subTotal', align: 'left', filterable: true },
        { text: 'IVA', value: 'iva', align: 'left', filterable: true },
        { text: 'TOTAL', value: 'total', align: 'left', filterable: true },
        { text: '1%', value: 'unoPorCiento', align: 'left', filterable: true },
        { text: '30%', value: 'treintaPorCiento', align: 'left', filterable: true },
        { text: 'GRAN TOTAL', value: 'granTotal', align: 'left', filterable: true },
      ],
      cargando: false,
      firstLoad: false,
      ordenarPor: '',
      advanceView: {
        arrastra: '',
      },
      requeridos: [
        value => !!value || 'Requerido',
      ],
      validAdvance: true,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      valAdvanceTotal: 0.00,
      valAdvanceTotalDrags: 0.00,
      valReceiptTotal: 0.00,
      valReceiptProductsTotal: 0.00,
      valGuideTotal: 0.00,
      calculateValsData: [],
      arrGideEndData: [],
      endTotalDataVal: 0,
      dieferenceTotalVal: 0,
      endTotalDataValReceipt: 0,
      dieferenceTotalValReceipt: 0,
    }),
    computed: {
      plan () {
        return this.$store.state.suscriptionPlan.plan
      },
      advanceLoadProviderLst () {
        return this.$store.state.advance.advancesProviderLst
      },
      entryGuidesProviderLst () {
        return this.$store.state.entryguides.entryGuidesLstProvider
      },
      entryReceiptProviderLst () {
        return this.$store.state.receipt.lstRecibosProveedores
      },
    },
    watch: {
      plan () {
        if (this.plan.diasConsulta) {
          this.minDateForConsults = moment().subtract(this.plan.diasConsulta, 'days').format('YYYY-MM-DD')
        }
      },
      providerData () {
        this.$store.dispatch('advance/loadAdvancesProvider', [this.dateFromAdvance, this.dateUntilAdvance, this.providerData.providerKey])
        this.$store.dispatch('entryguides/loadEntryGuidesFilterDateProvider', [this.dateFromAdvance, this.dateUntilAdvance, this.providerData.providerKey])
        this.$store.dispatch('receipt/loadReceiptsProviderFilter', [this.dateFromAdvance, this.dateUntilAdvance, this.providerData.providerKey])
      },
      advanceLoadProviderLst () {
        if (this.advanceLoadProviderLst.length > 0) {
          let sumTotal = 0
          for (let i = 0; i < this.advanceLoadProviderLst.length; i++) {
            sumTotal = parseFloat(sumTotal) + parseFloat(this.advanceLoadProviderLst[i].balance)
          }
          this.valAdvanceTotal = accounting.toFixed(sumTotal, 2)
        } else {
          this.valAdvanceTotal = 0.00
        }
        if (this.arrastra) {
          if (this.arrastra !== '') {
            this.valAdvanceTotalDrags = accounting.toFixed(parseFloat(this.valAdvanceTotal) - parseFloat(this.arrastra))
            this.endTotalDataVal = accounting.toFixed(this.endTotalDataVal, 2)
            this.dieferenceTotalVal = accounting.toFixed(parseFloat(this.valAdvanceTotalDrags) - parseFloat(this.endTotalDataVal), 2)
            this.endTotalDataValReceipt = accounting.toFixed(this.endTotalDataValReceipt, 2)
            this.dieferenceTotalValReceipt = accounting.toFixed(parseFloat(this.valAdvanceTotalDrags) - parseFloat(this.endTotalDataValReceipt), 2)
          } else {
            this.valAdvanceTotalDrags = accounting.toFixed(parseFloat(this.valAdvanceTotal))
            this.endTotalDataVal = accounting.toFixed(this.endTotalDataVal, 2)
            this.dieferenceTotalVal = accounting.toFixed(parseFloat(this.valAdvanceTotalDrags) - parseFloat(this.endTotalDataVal), 2)
            this.endTotalDataValReceipt = accounting.toFixed(this.endTotalDataValReceipt, 2)
            this.dieferenceTotalValReceipt = accounting.toFixed(parseFloat(this.valAdvanceTotalDrags) - parseFloat(this.endTotalDataValReceipt), 2)
          }
        } else {
          this.valAdvanceTotalDrags = accounting.toFixed(parseFloat(this.valAdvanceTotal))
          this.endTotalDataVal = accounting.toFixed(this.endTotalDataVal, 2)
          this.dieferenceTotalVal = accounting.toFixed(parseFloat(this.valAdvanceTotalDrags) - parseFloat(this.endTotalDataVal), 2)
          this.endTotalDataValReceipt = accounting.toFixed(this.endTotalDataValReceipt, 2)
          this.dieferenceTotalValReceipt = accounting.toFixed(parseFloat(this.valAdvanceTotalDrags) - parseFloat(this.endTotalDataValReceipt), 2)
        }
      },
      entryReceiptProviderLst () {
        if (this.entryReceiptProviderLst) {
          if (this.entryReceiptProviderLst.receipts) {
            if (this.entryReceiptProviderLst.receipts.length > 0) {
              let sumTotal = 0
              for (let i = 0; i < this.entryReceiptProviderLst.receipts.length; i++) {
                sumTotal = parseFloat(sumTotal) + parseFloat(this.entryReceiptProviderLst.receipts[i].total)
              }
              this.valReceiptTotal = accounting.toFixed(sumTotal, 2)
            } else {
              this.valReceiptTotal = 0.00
            }
          } else {
            this.valReceiptTotal = 0.00
          }
        } else {
          this.valReceiptTotal = 0.00
        }
        if (this.entryReceiptProviderLst) {
          if (this.entryReceiptProviderLst.lstReceiptsProductos) {
            if (this.entryReceiptProviderLst.lstReceiptsProductos.length > 0) {
              let sumTotal = 0
              for (let i = 0; i < this.entryReceiptProviderLst.lstReceiptsProductos.length; i++) {
                sumTotal = parseFloat(sumTotal) + parseFloat(this.entryReceiptProviderLst.lstReceiptsProductos[i].total)
              }
              this.valReceiptProductsTotal = accounting.toFixed(sumTotal, 2)
            } else {
              this.valReceiptProductsTotal = 0.00
            }
          } else {
            this.valReceiptProductsTotal = 0.00
          }
        } else {
          this.valReceiptProductsTotal = 0.00
        }
      },
      entryGuidesProviderLst () {
        if (this.providerData.external) {
          let banAlert = false
          let cont = 0
          let banContinue = true
          let sumTotal = 0
          if (this.entryGuidesProviderLst.length > 0) {
            while (banContinue && cont < this.entryGuidesProviderLst.length) {
              if (this.entryGuidesProviderLst[cont].kiloprice) {
                if (this.entryGuidesProviderLst[cont].kiloprice !== '') {
                  if (this.entryGuidesProviderLst[cont].weight) {
                    if (this.entryGuidesProviderLst[cont].weight !== '') {
                      banAlert = false
                      sumTotal = parseFloat(sumTotal) + parseFloat(this.entryGuidesProviderLst[cont].total)
                    }
                  } else {
                    banAlert = true
                    banContinue = false
                  }
                } else {
                  banAlert = true
                  banContinue = false
                }
              } else {
                banAlert = true
                banContinue = false
              }
              cont++
            }
          }
          if (banAlert) {
            this.msgAlertValsData = 'Debe verificar los datos agregados de la guía en el registro ' + cont +
              ' debe verificar el PESO VERIFICADO y el PRECIO KILO.'
            this.alertDataSend = true
            this.valGuideTotal = 0
          } else {
            this.valGuideTotal = accounting.toFixed(sumTotal, 2)
          }
        }
      },
    },
    methods: {
      downloadData () {
        if (this.providerData.external) {
          let valLength1 = 0
          let valLength2 = 0
          let valLength3 = 0

          if (this.advanceLoadProviderLst) {
            valLength1 = this.advanceLoadProviderLst.length
          }

          if (this.entryGuidesProviderLst) {
            valLength2 = this.entryGuidesProviderLst.length
          }

          if (this.arrGideEndData) {
            valLength3 = this.arrGideEndData.length
          }

          const data = []
          const lengthValid = Math.max(valLength1, valLength2, valLength3)

          if (lengthValid > 0) {
            for (let i = 0; i <= (lengthValid + 1); i++) {
              const dataSend = {
                'Fecha Anticipo': '',
                'Valor Anticipo': '',
                'Saldo Anticipo': '',
                '-': '',
                Fecha: '',
                'N° Guía': '',
                'N° Placa': '',
                'Peso Entrada': '',
                Descuentos: '',
                Material: '',
                'Peso Verificado': '',
                'Precio Kilo': '',
                Total: '',
                '--': '',
                'PESO VERIFICADO CALCULADO': '',
                'PRECIO KILO CALCULADO': '',
                'SUB TOTAL': '',
                IVA: '',
                'TOTAL CALCULADO': '',
                '1%': '',
                '30%': '',
                'GRAN TOTAL': '',
              }
              if (this.advanceLoadProviderLst) {
                if (i === 0) {
                  dataSend['Fecha Anticipo'] = 'Arrastra: '
                  dataSend['Valor Anticipo'] = ''
                  dataSend['Saldo Anticipo'] = this.arrastra.toString().replace('.', ',')
                } else if (i <= this.advanceLoadProviderLst.length) {
                  dataSend['Fecha Anticipo'] = this.advanceLoadProviderLst[i - 1].createDate
                  dataSend['Valor Anticipo'] = this.advanceLoadProviderLst[i - 1].total.toString().replace('.', ',')
                  dataSend['Saldo Anticipo'] = this.advanceLoadProviderLst[i - 1].balance.toString().replace('.', ',')
                } else if (i === (this.advanceLoadProviderLst.length + 1)) {
                  dataSend['Fecha Anticipo'] = ''
                  dataSend['Valor Anticipo'] = 'TOTAL: '
                  dataSend['Saldo Anticipo'] = this.valAdvanceTotalDrags.toString().replace('.', ',')
                } else {
                  dataSend['Fecha Anticipo'] = ''
                  dataSend['Valor Anticipo'] = ''
                  dataSend['Saldo Anticipo'] = ''
                }
              }
              dataSend['-'] = ''
              if (this.entryGuidesProviderLst) {
                if (i < this.entryGuidesProviderLst.length) {
                  dataSend.Fecha = this.entryGuidesProviderLst[i].dateEntry
                  dataSend['N° Guía'] = this.entryGuidesProviderLst[i].referralGuide
                  dataSend['N° Placa'] = this.entryGuidesProviderLst[i].plate
                  dataSend['Peso Entrada'] = this.entryGuidesProviderLst[i].inputweight.toString().replace('.', ',')
                  dataSend.Descuentos = this.entryGuidesProviderLst[i].discounts.toString().replace('.', ',')
                  dataSend.Material = this.entryGuidesProviderLst[i].item
                  dataSend['Peso Verificado'] = this.entryGuidesProviderLst[i].weight.toString().replace('.', ',')
                  dataSend['Precio Kilo'] = this.entryGuidesProviderLst[i].kiloprice.toString().replace('.', ',')
                  dataSend.Total = this.entryGuidesProviderLst[i].total.toString().replace('.', ',')
                } else if (i === this.entryGuidesProviderLst.length) {
                  dataSend.Fecha = ''
                  dataSend['N° Guía'] = ''
                  dataSend['N° Placa'] = ''
                  dataSend['Peso Entrada'] = ''
                  dataSend.Descuentos = ''
                  dataSend.Material = ''
                  dataSend['Peso Verificado'] = ''
                  dataSend['Precio Kilo'] = 'TOTAL: '
                  dataSend.Total = this.valGuideTotal.toString().replace('.', ',')
                } else {
                  dataSend.Fecha = ''
                  dataSend['N° Guía'] = ''
                  dataSend['N° Placa'] = ''
                  dataSend['Peso Entrada'] = ''
                  dataSend.Descuentos = ''
                  dataSend.Material = ''
                  dataSend['Peso Verificado'] = ''
                  dataSend['Precio Kilo'] = ''
                  dataSend.Total = ''
                }
              }
              dataSend['--'] = ''
              if (this.arrGideEndData) {
                if (i < this.arrGideEndData.length) {
                  dataSend['PESO VERIFICADO CALCULADO'] = this.arrGideEndData[i].pesoVerificado.toString().replace('.', ',')
                  dataSend['PRECIO KILO CALCULADO'] = this.arrGideEndData[i].precioKilo.toString().replace('.', ',')
                  dataSend['SUB TOTAL'] = this.arrGideEndData[i].subTotal.toString().replace('.', ',')
                  dataSend.IVA = this.arrGideEndData[i].iva.toString().replace('.', ',')
                  dataSend['TOTAL CALCULADO'] = this.arrGideEndData[i].total.toString().replace('.', ',')
                  dataSend['1%'] = this.arrGideEndData[i].unoPorCiento.toString().replace('.', ',')
                  dataSend['30%'] = this.arrGideEndData[i].treintaPorCiento.toString().replace('.', ',')
                  dataSend['GRAN TOTAL'] = this.arrGideEndData[i].granTotal.toString().replace('.', ',')
                } else if (i === this.arrGideEndData.length) {
                  dataSend['PESO VERIFICADO CALCULADO'] = ''
                  dataSend['PRECIO KILO CALCULADO'] = ''
                  dataSend['SUB TOTAL'] = ''
                  dataSend.IVA = ''
                  dataSend['TOTAL CALCULADO'] = ''
                  dataSend['1%'] = ''
                  dataSend['30%'] = 'TOTAL FINAL: '
                  dataSend['GRAN TOTAL'] = this.endTotalDataVal.toString().replace('.', ',')
                } else if (i === (this.arrGideEndData.length + 1)) {
                  dataSend['PESO VERIFICADO CALCULADO'] = ''
                  dataSend['PRECIO KILO CALCULADO'] = ''
                  dataSend['SUB TOTAL'] = ''
                  dataSend.IVA = ''
                  dataSend['TOTAL CALCULADO'] = ''
                  dataSend['1%'] = ''
                  dataSend['30%'] = 'DIFERENCIA: '
                  dataSend['GRAN TOTAL'] = this.dieferenceTotalVal.toString().replace('.', ',')
                } else {
                  dataSend['PESO VERIFICADO CALCULADO'] = ''
                  dataSend['PRECIO KILO CALCULADO'] = ''
                  dataSend['SUB TOTAL'] = ''
                  dataSend.IVA = ''
                  dataSend['TOTAL CALCULADO'] = ''
                  dataSend['1%'] = ''
                  dataSend['30%'] = ''
                  dataSend['GRAN TOTAL'] = ''
                }
              }
              data.push(dataSend)
            }
            functionsVal.JSONToCSVConvertor(data, 'Calculos-Finales-' + this.dateFromAdvance + '-' + this.dateUntilAdvance, true)
          }
        } else {
          let valLength1 = 0
          let valLength2 = 0
          let valLength3 = 0

          if (this.advanceLoadProviderLst) {
            valLength1 = this.advanceLoadProviderLst.length
          }

          if (this.entryReceiptProviderLst) {
            if (this.entryReceiptProviderLst.receipts) {
              valLength2 = this.entryReceiptProviderLst.receipts.length
            }
          }

          if (this.calculateValsData) {
            valLength3 = this.calculateValsData.length
          }

          const data = []
          const lengthValid = Math.max(valLength1, valLength2, valLength3)

          if (lengthValid > 0) {
            for (let i = 0; i <= (lengthValid + 1); i++) {
              const dataSend = {
                'Fecha Anticipo': '',
                'Valor Anticipo': '',
                'Saldo Anticipo': '',
                '-': '',
                'Fecha Recibo': '',
                'N° Recibo': '',
                Proveedor: '',
                'Peso Recibo': '',
                'Material Recibo': '',
                'Precio Recibo': '',
                'Total Recibo': '',
                '--': '',
                'PESO VERIFICADO CALCULADO': '',
                'PRECIO KILO CALCULADO': '',
                'SUB TOTAL': '',
                IVA: '',
                'TOTAL CALCULADO': '',
                '1%': '',
                '30%': '',
                'GRAN TOTAL': '',
              }
              if (this.advanceLoadProviderLst) {
                if (i === 0) {
                  dataSend['Fecha Anticipo'] = 'Arrastra: '
                  dataSend['Valor Anticipo'] = ''
                  dataSend['Saldo Anticipo'] = this.arrastra.toString().replace('.', ',')
                } else if (i <= this.advanceLoadProviderLst.length) {
                  dataSend['Fecha Anticipo'] = this.advanceLoadProviderLst[i - 1].createDate
                  dataSend['Valor Anticipo'] = this.advanceLoadProviderLst[i - 1].total.toString().replace('.', ',')
                  dataSend['Saldo Anticipo'] = this.advanceLoadProviderLst[i - 1].balance.toString().replace('.', ',')
                } else if (i === (this.advanceLoadProviderLst.length + 1)) {
                  dataSend['Fecha Anticipo'] = ''
                  dataSend['Valor Anticipo'] = 'TOTAL: '
                  dataSend['Saldo Anticipo'] = this.valAdvanceTotalDrags.toString().replace('.', ',')
                } else {
                  dataSend['Fecha Anticipo'] = ''
                  dataSend['Valor Anticipo'] = ''
                  dataSend['Saldo Anticipo'] = ''
                }
              }
              dataSend['-'] = ''
              if (this.entryReceiptProviderLst) {
                if (this.entryReceiptProviderLst.lstReceiptsProductos) {
                  if (i < this.entryReceiptProviderLst.lstReceiptsProductos.length) {
                    dataSend['Fecha Recibo'] = this.entryReceiptProviderLst.lstReceiptsProductos[i].fechaEmision
                    dataSend['N° Recibo'] = this.entryReceiptProviderLst.lstReceiptsProductos[i].secuencial
                    dataSend.Proveedor = this.entryReceiptProviderLst.lstReceiptsProductos[i].proveedor.completeData
                    dataSend['Peso Recibo'] = this.entryReceiptProviderLst.lstReceiptsProductos[i].cantidad.toString().replace('.', ',')
                    dataSend['Material Recibo'] = this.entryReceiptProviderLst.lstReceiptsProductos[i].name
                    dataSend['Precio Recibo'] = this.entryReceiptProviderLst.lstReceiptsProductos[i].valorUnitario.toString().replace('.', ',')
                    dataSend['Total Recibo'] = this.entryReceiptProviderLst.lstReceiptsProductos[i].total.toString().replace('.', ',')
                  } else if (i === this.entryReceiptProviderLst.lstReceiptsProductos.length) {
                    dataSend['Fecha Recibo'] = ''
                    dataSend['N° Recibo'] = ''
                    dataSend.Proveedor = ''
                    dataSend['Peso Recibo'] = ''
                    dataSend['Material Recibo'] = ''
                    dataSend['Precio Recibo'] = 'TOTAL: '
                    dataSend['Total Recibo'] = this.valReceiptProductsTotal.toString().replace('.', ',')
                  } else {
                    dataSend['Fecha Recibo'] = ''
                    dataSend['N° Recibo'] = ''
                    dataSend.Proveedor = ''
                    dataSend['Peso Recibo'] = ''
                    dataSend['Material Recibo'] = ''
                    dataSend['Precio Recibo'] = ''
                    dataSend['Total Recibo'] = ''
                  }
                }
              }
              dataSend['--'] = ''
              if (this.calculateValsData) {
                if (i < this.calculateValsData.length) {
                  dataSend['PESO VERIFICADO CALCULADO'] = this.calculateValsData[i].pesoVerificado.toString().replace('.', ',')
                  dataSend['PRECIO KILO CALCULADO'] = this.calculateValsData[i].precioKilo.toString().replace('.', ',')
                  dataSend['SUB TOTAL'] = this.calculateValsData[i].subTotal.toString().replace('.', ',')
                  dataSend.IVA = this.calculateValsData[i].iva.toString().replace('.', ',')
                  dataSend['TOTAL CALCULADO'] = this.calculateValsData[i].total.toString().replace('.', ',')
                  dataSend['1%'] = this.calculateValsData[i].unoPorCiento.toString().replace('.', ',')
                  dataSend['30%'] = this.calculateValsData[i].treintaPorCiento.toString().replace('.', ',')
                  dataSend['GRAN TOTAL'] = this.calculateValsData[i].granTotal.toString().replace('.', ',')
                } else if (i === this.calculateValsData.length) {
                  dataSend['PESO VERIFICADO CALCULADO'] = ''
                  dataSend['PRECIO KILO CALCULADO'] = ''
                  dataSend['SUB TOTAL'] = ''
                  dataSend.IVA = ''
                  dataSend['TOTAL CALCULADO'] = ''
                  dataSend['1%'] = ''
                  dataSend['30%'] = 'TOTAL FINAL: '
                  dataSend['GRAN TOTAL'] = this.endTotalDataValReceipt.toString().replace('.', ',')
                } else if (i === (this.calculateValsData.length + 1)) {
                  dataSend['PESO VERIFICADO CALCULADO'] = ''
                  dataSend['PRECIO KILO CALCULADO'] = ''
                  dataSend['SUB TOTAL'] = ''
                  dataSend.IVA = ''
                  dataSend['TOTAL CALCULADO'] = ''
                  dataSend['1%'] = ''
                  dataSend['30%'] = 'DIFERENCIA: '
                  dataSend['GRAN TOTAL'] = this.dieferenceTotalValReceipt.toString().replace('.', ',')
                } else {
                  dataSend['PESO VERIFICADO CALCULADO'] = ''
                  dataSend['PRECIO KILO CALCULADO'] = ''
                  dataSend['SUB TOTAL'] = ''
                  dataSend.IVA = ''
                  dataSend['TOTAL CALCULADO'] = ''
                  dataSend['1%'] = ''
                  dataSend['30%'] = ''
                  dataSend['GRAN TOTAL'] = ''
                }
              }
              data.push(dataSend)
            }
            functionsVal.JSONToCSVConvertor(data, 'Calculos-Finales-' + this.dateFromAdvance + '-' + this.dateUntilAdvance, true)
          }
        }
      },
      closeModal () {
        this.dateUntilAdvance = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10)
        this.dateFromAdvance = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().substr(0, 10)
        this.arrastra = ''
        this.calculateValsData = []
        this.arrGideEndData = []
        this.valAdvanceTotal = 0.00
        this.valAdvanceTotalDrags = 0.00
        this.valReceiptTotal = 0.00
        this.valReceiptProductsTotal = 0.00
        this.valGuideTotal = 0.00
        this.endTotalDataVal = 0
        this.dieferenceTotalVal = 0
        this.endTotalDataValReceipt = 0
        this.dieferenceTotalValReceipt = 0
        this.$emit('closeModalAdvance')
      },
      calcAdvanceVal () {
        if (this.arrastra) {
          if (this.arrastra !== '') {
            this.valAdvanceTotalDrags = accounting.toFixed(parseFloat(this.valAdvanceTotal) - parseFloat(this.arrastra))
            this.endTotalDataVal = accounting.toFixed(this.endTotalDataVal, 2)
            this.dieferenceTotalVal = accounting.toFixed(parseFloat(this.valAdvanceTotalDrags) - parseFloat(this.endTotalDataVal), 2)
            this.endTotalDataValReceipt = accounting.toFixed(this.endTotalDataValReceipt, 2)
            this.dieferenceTotalValReceipt = accounting.toFixed(parseFloat(this.valAdvanceTotalDrags) - parseFloat(this.endTotalDataValReceipt), 2)
          } else {
            this.valAdvanceTotalDrags = accounting.toFixed(parseFloat(this.valAdvanceTotal))
            this.endTotalDataVal = accounting.toFixed(this.endTotalDataVal, 2)
            this.dieferenceTotalVal = accounting.toFixed(parseFloat(this.valAdvanceTotalDrags) - parseFloat(this.endTotalDataVal), 2)
            this.endTotalDataValReceipt = accounting.toFixed(this.endTotalDataValReceipt, 2)
            this.dieferenceTotalValReceipt = accounting.toFixed(parseFloat(this.valAdvanceTotalDrags) - parseFloat(this.endTotalDataValReceipt), 2)
          }
        } else {
          this.valAdvanceTotalDrags = accounting.toFixed(parseFloat(this.valAdvanceTotal))
          this.endTotalDataVal = accounting.toFixed(this.endTotalDataVal, 2)
          this.dieferenceTotalVal = accounting.toFixed(parseFloat(this.valAdvanceTotalDrags) - parseFloat(this.endTotalDataVal), 2)
          this.endTotalDataValReceipt = accounting.toFixed(this.endTotalDataValReceipt, 2)
          this.dieferenceTotalValReceipt = accounting.toFixed(parseFloat(this.valAdvanceTotalDrags) - parseFloat(this.endTotalDataValReceipt), 2)
        }
      },
      calculateVals () {
        this.arrGideEndData = []
        this.calculateValsData = []
        if (this.providerData.external) {
          if (this.alertDataSend) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Para continuar debe validar los registros de las guías ya que se tiene errores en los mismos.',
            }
            return
          }
          const arrGroup = functions.groupBy(this.entryGuidesProviderLst, guide => guide.codeProduct)
          const arrGroupKeys = Array.from(arrGroup.keys())
          for (let i = 0; i < arrGroupKeys.length; i++) {
            const newDataAdd = {
              pesoVerificado: '',
              precioKilo: '',
              subTotal: '',
              iva: '',
              total: '',
              unoPorCiento: '',
              treintaPorCiento: '',
              granTotal: '',
            }
            const arrGuideGroup = arrGroup.get(arrGroupKeys[i].toString())
            let sumTotEnd = 0
            let precioKilo = 0
            for (let j = 0; j < arrGuideGroup.length; j++) {
              sumTotEnd = parseFloat(sumTotEnd) + parseFloat(arrGuideGroup[j].weight)
              if (j === 0) {
                precioKilo = arrGuideGroup[j].kiloprice
              }
            }
            newDataAdd.pesoVerificado = accounting.toFixed(sumTotEnd, 2)
            newDataAdd.precioKilo = accounting.toFixed(precioKilo, 2)
            newDataAdd.subTotal = accounting.toFixed(parseFloat(newDataAdd.pesoVerificado) * parseFloat(newDataAdd.precioKilo), 2)
            newDataAdd.iva = accounting.toFixed(parseFloat(newDataAdd.subTotal) * parseFloat(0.12), 2)
            newDataAdd.total = accounting.toFixed(parseFloat(newDataAdd.subTotal) + parseFloat(newDataAdd.iva), 2)
            newDataAdd.unoPorCiento = accounting.toFixed(parseFloat(newDataAdd.subTotal) * parseFloat(0.01), 2)
            newDataAdd.treintaPorCiento = accounting.toFixed(parseFloat(newDataAdd.subTotal) * parseFloat(0.3), 2)
            newDataAdd.granTotal = accounting.toFixed(parseFloat(newDataAdd.total) - parseFloat(newDataAdd.unoPorCiento) - parseFloat(newDataAdd.unoPorCiento, 2))
            this.arrGideEndData.push(newDataAdd)
          }
          let sumEndTotal = 0
          for (let i = 0; i < this.arrGideEndData.length; i++) {
            sumEndTotal = parseFloat(sumEndTotal) + parseFloat(this.arrGideEndData[i].granTotal)
          }
          this.endTotalDataVal = accounting.toFixed(sumEndTotal, 2)
          this.dieferenceTotalVal = accounting.toFixed(parseFloat(this.valAdvanceTotalDrags) - parseFloat(this.endTotalDataVal), 2)
        } else {
          const arrGroup = functions.groupBy(this.entryReceiptProviderLst.lstReceiptsProductos, product => product.codeProduct)
          const arrGroupKeys = Array.from(arrGroup.keys())
          for (let i = 0; i < arrGroupKeys.length; i++) {
            const newDataAdd = {
              pesoVerificado: '',
              precioKilo: '',
              subTotal: '',
              iva: '',
              total: '',
              unoPorCiento: '',
              treintaPorCiento: '',
              granTotal: '',
            }
            const arrGuideGroup = arrGroup.get(arrGroupKeys[i].toString())
            let sumTotEnd = 0
            let precioKilo = 0
            for (let j = 0; j < arrGuideGroup.length; j++) {
              sumTotEnd = parseFloat(sumTotEnd) + parseFloat(arrGuideGroup[j].cantidad)
              if (j === 0) {
                precioKilo = arrGuideGroup[j].valorUnitario
              }
            }
            newDataAdd.pesoVerificado = accounting.toFixed(sumTotEnd, 2)
            newDataAdd.precioKilo = accounting.toFixed(precioKilo, 2)
            newDataAdd.subTotal = accounting.toFixed(parseFloat(newDataAdd.pesoVerificado) * parseFloat(newDataAdd.precioKilo), 2)
            newDataAdd.iva = accounting.toFixed(parseFloat(newDataAdd.subTotal) * parseFloat(0.12), 2)
            newDataAdd.total = accounting.toFixed(parseFloat(newDataAdd.subTotal) + parseFloat(newDataAdd.iva), 2)
            newDataAdd.unoPorCiento = accounting.toFixed(parseFloat(newDataAdd.subTotal) * parseFloat(0.01), 2)
            newDataAdd.treintaPorCiento = accounting.toFixed(parseFloat(newDataAdd.subTotal) * parseFloat(0.3), 2)
            newDataAdd.granTotal = accounting.toFixed(parseFloat(newDataAdd.total) - parseFloat(newDataAdd.unoPorCiento) - parseFloat(newDataAdd.unoPorCiento, 2))
            this.calculateValsData.push(newDataAdd)
          }
          let sumEndTotal = 0
          for (let i = 0; i < this.calculateValsData.length; i++) {
            sumEndTotal = parseFloat(sumEndTotal) + parseFloat(this.calculateValsData[i].granTotal)
          }
          this.endTotalDataValReceipt = accounting.toFixed(sumEndTotal, 2)
          this.dieferenceTotalValReceipt = accounting.toFixed(parseFloat(this.valAdvanceTotalDrags) - parseFloat(this.endTotalDataValReceipt), 2)
        }
      },
      async filterByDate () {
        this.arrastra = ''
        this.calculateValsData = []
        this.arrGideEndData = []
        this.valAdvanceTotal = 0.00
        this.valAdvanceTotalDrags = 0.00
        this.valReceiptTotal = 0.00
        this.valReceiptProductsTotal = 0.00
        this.valGuideTotal = 0.00
        this.endTotalDataVal = 0
        this.dieferenceTotalVal = 0
        this.endTotalDataValReceipt = 0
        this.dieferenceTotalValReceipt = 0
        this.$store.dispatch('advance/loadAdvancesProvider', [this.dateFromAdvance, this.dateUntilAdvance, this.providerData.providerKey])
        this.$store.dispatch('entryguides/loadEntryGuidesFilterDateProvider', [this.dateFromAdvance, this.dateUntilAdvance, this.providerData.providerKey])
        this.$store.dispatch('receipt/loadReceiptsProviderFilter', [this.dateFromAdvance, this.dateUntilAdvance, this.providerData.providerKey])
        // this.calculateVals()
      },
      isNumber (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
    },
    mounted () {
      if (this.plan.diasConsulta) {
        this.minDateForConsults = moment().subtract(this.plan.diasConsulta, 'days').format('YYYY-MM-DD')
      }
      this.$store.dispatch('advance/loadAdvancesProvider', [this.dateFromAdvance, this.dateUntilAdvance, this.providerData.providerKey])
      this.$store.dispatch('entryguides/loadEntryGuidesFilterDateProvider', [this.dateFromAdvance, this.dateUntilAdvance, this.providerData.providerKey])
      this.$store.dispatch('receipt/loadReceiptsProviderFilter', [this.dateFromAdvance, this.dateUntilAdvance, this.providerData.providerKey])
    },
  }
</script>
